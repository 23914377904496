/* eslint-disable jsx-a11y/alt-text */
import Image from "next/image";
import { useEffect, useState } from "react";

interface ImageFallbackProps {
  src: string;
  fallback: string;
  layout?: "fill" | "fixed" | "intrinsic" | "responsive";
  width?: number;
  height?: number;
  styles?: React.CSSProperties;
  alt?: string;
}

const ImageFallback: React.FC<ImageFallbackProps> = ({
  src,
  fallback,
  layout = "responsive",
  width,
  height,
  styles,
  alt,
  ...rest
}) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <Image
      {...rest}
      src={imgSrc}
      onError={() => {
        setImgSrc(fallback);
      }}
      layout={layout}
      width={width}
      height={height}
      style={styles}
      alt={alt}
    />
  );
};

export default ImageFallback;
