import Link from "next/link";
import ImageFallback from "../utilities/ImageFallback";
import { content } from "@/components/providers/data";

const HeroSection = () => {
  return (
    <section className="section dark:bg-darkmode-theme-dark p-4 md:p-12">
      <div className="container">
        <div className="flex flex-col items-center justify-center">
          <div className="text-center">
            <h1 className="mb-2 text-h3 lg:text-h1 text-black">
              {content.hero.title}
            </h1>
            <p className="text-justify dark:text-white">
              {content.hero.description}
            </p>
          </div>
          {true && (
            <div className="col-6">
              <ImageFallback
                src={content.hero.imageSrc}
                className="mx-auto"
                width="800"
                height="420"
                alt="banner image"
                priority
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
