// components/HoursAndLocation.js
import { content } from "@/components/providers/data";

export default function HoursAndLocation() {
  return (
    <section className="section text-black dark:text-dark p-4 md:p-14 bg-white">
      <div className="container">
        <div className="flex items-center justify-center">
          <div className="text-center">
            <h2 className="mb-4 text-h3 text-black lg:text-h1 dark:text-dark">
              {content.hoursAndLocation.title}
            </h2>
            <div className="text-2xl pt-3 text-black text-center uppercase underline text-center">
              {content.hoursAndLocation.appointmentText}
            </div>
            <div className="text-center text-black dark:text-dark py-3">
              <a href="tel:4237327000" className="text-4xl tracking-widest">
                {content.hoursAndLocation.phone}
              </a>
            </div>
          </div>
        </div>
        <div className="relative overflow-x-auto flex justify-center mt-3">
          <div className="flex justify-center m-5">
            <table className="text-sm text-gray-500 dark:text-gray-400 border border-1 shadow-lg">
              <thead>
                <tr className="border-2 border-collapse border-gray-900">
                  <th
                    className="border-2 border-gray-900 py-2 tracking-widest bg-[#504746b3] dark:bg-[#FBB7C0] text-white dark:text-black"
                    colSpan="2"
                  >
                    Hours of Operation
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">
                {content.hours.map((item, index) => (
                  <tr key={index} className="border-2 border-gray-900 py-2">
                    <td className="w-40 sm:w-64 border-2 border-gray-900 py-2 tracking-wider font-medium">
                      {item.day}
                    </td>
                    <td className="w-64 border-2 border-gray-900 py-2 tracking-wider font-medium">
                      <code>{item.time}</code>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}
