// components/FeaturesList.js
import ImageFallback from "../utilities/ImageFallback";
import Link from "next/link";
import { content } from "@/components/providers/data";

const FeaturesList = () => {
  const features = content.features || [];

  const checkIcon = (
    <svg
      className="absolute left-0 top-1.5"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="currentColor"
      width="16"
      height="16"
    >
      <path d="M13.485 1.929a1 1 0 0 1 0 1.414l-7.071 7.071a1 1 0 0 1-1.414 0L2.343 8.343a1 1 0 1 1 1.414-1.414l1.657 1.657L12.07 1.93a1 1 0 0 1 1.415 0z" />
    </svg>
  );

  return (
    <>
      {features.map((feature, index) => (
        <section
          key={index}
          className={`section text-black dark:text-dark p-4 md:p-14 ${
            index % 2 === 0 ? "bg-white" : ""
          }`}
        >
          <div className="container mx-auto">
            <div className="flex flex-col xl:flex-row items-center">
              <div
                className={`mb:md-0 mb-6 lg:w-4/4 ${
                  index % 2 !== 0 ? "md:order-2" : ""
                }`}
              >
                <ImageFallback
                  src={feature.image}
                  height={200}
                  width={500}
                  alt={feature.title}
                  layout="intrinsic"
                />
              </div>
              <div
                className={`md:w-3/4 lg:w-4/4 pl-4 ${
                  index % 2 !== 0 ? "md:order-1" : ""
                }`}
              >
                <h2 className="mb-4 text-black dark:text-dark">
                  {feature.title}
                </h2>
                <p className="mb-8 text-lg text-black dark:text-dark">
                  {feature.content}
                </p>
                <ul>
                  {feature.bulletpoints.map((bullet, bulletIndex) => (
                    <li
                      className="relative mb-4 pl-6 text-black dark:text-dark"
                      key={bulletIndex}
                    >
                      {checkIcon}
                      <span>{bullet}</span>
                    </li>
                  ))}
                </ul>
                {feature.button.enable && (
                  <Link
                    className="btn btn-primary mt-5"
                    href={feature.button.link}
                  >
                    {feature.button.label}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </section>
      ))}
    </>
  );
};

export default FeaturesList;
