// components/FeaturedServices.js
import { content } from "@/components/providers/data";

const FeaturedServices = () => {
  const featuredServices = content.featured?.services || []; // Fetching services from content.json

  const truncateContent = (content, charLimit) => {
    return content.length > charLimit
      ? content.slice(0, charLimit) + "..."
      : content;
  };

  return (
    <div className="section dark:bg-darkmode-theme-dark py-14">
      <div className="container">
        <h2 className="text-center text-black pb-4">
          {content.featured?.title}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 px-4">
          {featuredServices.map((service, index) => (
            <div
              key={index}
              className="w-full bg-white border border-gray-200 rounded-lg dark:bg-[#FBB7C0] dark:border-gray-700 mx-auto text-center shadow-lg"
            >
              <div
                className={`p-8 rounded-lg ${
                  index % 2 === 0
                    ? "bg-[#FBB7C0] dark:bg-gray-800"
                    : "bg-theme-light dark:bg-theme-dark"
                } h-6 md:h-32 w-full flex justify-center items-center mb-2`}
              >
                <div className="text-center">
                  <h2 className="text-3xl sm:text-xl font-bold text-gray-700 dark:text-white">
                    {service.title}
                  </h2>
                  <p className="mt-2 text-gray-600 dark:text-gray-400">
                    {service.description}
                  </p>
                </div>
              </div>
              <div className="px-5 pb-5">
                <p className="text-justify dark:text-gray-900">
                  {truncateContent(service.content, 180)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedServices;
