// pages/_app.js
import FeaturedServices from "@/components/partial/FeaturedServices";
import FeaturesList from "@/components/partial/FeaturesList";
import HeroSection from "@/components/partial/HeroSection";
import HoursAndLocation from "@/components/partial/HoursAndLocation";
import Testimonials from "@/components/partial/Testimonials";


function MyApp() {
  return (
    <main className="flex flex-col justify-center bg-body">
      <HeroSection />
      <HoursAndLocation />
      <FeaturedServices />
      <FeaturesList />
      <Testimonials />
    </main>
  );
}

export default MyApp;
